import React, {Component} from 'react';
import {withRouter} from "../../hoc/withRouter";
import Search from "antd/es/input/Search";
import {SearchOutlined, HistoryOutlined} from "@ant-design/icons";
import Input from "antd/lib/input";
import Drawer from "antd/lib/drawer";
import {Divider, InputRef, Space} from "antd";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

class SearchComponent extends Component<any, any> {
    private readonly initState!: any;
    private readonly myRef: React.RefObject<any>;

    constructor(props: any) {
        super(props);
        this.myRef = React.createRef<InputRef>();

        this.initState = {
            openDrawer: false,
            searchText: "",
        }
        this.state = {...this.initState}
    }

    onFieldChange = <T extends keyof Pick<any, "searchText">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "searchText":
                currentState.searchText = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }
    showFilterDrawer = () => {
        this.setState({
            openDrawer: true,
            searchText: ""
        }, () => {
        });
    }
    searchFreeText = async (searchText: string) => {
        if (searchText?.trim()?.length < 3) {
            Modal.error({
                title: 'Pencarian Gagal',
                content: 'mohon isi field pencarian minimal 3 karakter',
            });
            return;
        }
        this.props.navigate(`/baru/${this.props.areaCode?.toLowerCase()}/variant?search=${searchText?.trim()}`);
    }

    render() {
        let lastSeenVariant: any = localStorage.getItem('lastSeenVariant');
        lastSeenVariant = lastSeenVariant ? JSON.parse(lastSeenVariant) : [];
        return (
            <React.Fragment>
                <div onClick={this.showFilterDrawer} className={"search-box-home"}>
                    <Input
                        style={{borderRadius: 7}}
                        size="large"
                        placeholder="Temukan Motor, Katalog dan lainya"
                        prefix={<SearchOutlined/>}
                        value={``}
                    />
                </div>
                <Drawer
                    title=""
                    placement="top"
                    getContainer={false}
                    onClose={() => this.setState({openDrawer: false})}
                    open={this.state.openDrawer}
                    height={(this.props.isDesktop) ? "75vh" : "100vh"}
                    afterOpenChange={() => this.myRef.current!?.focus({cursor: 'start'})}
                    extra={
                        <Space>
                            <span onClick={() => this.searchFreeText(this.state.searchText)} style={{cursor: "pointer"}}><u><b>Cari</b></u></span>
                        </Space>
                    }
                >
                    <Search
                        ref={this.myRef}
                        autoFocus={true}
                        size={"large"} value={this.state.searchText}
                        onChange={event => this.onFieldChange("searchText", event.target.value)}
                        placeholder="Temukan Motor, Katalog dan lainya"
                        onSearch={this.searchFreeText}
                    />

                    <Divider style={{margin: 0, padding: 5}}/>
                    <span style={{color: "#b0b0b0", marginTop: 10}}>PENCARIAN POPULER:</span>
                    <div style={{lineHeight: 0}}>
                        <div onClick={() => this.searchFreeText("Honda Beat")} className={`search-result`}><HistoryOutlined/> Honda Beat</div>
                        <div onClick={() => this.searchFreeText("Stylo")} className={`search-result`}><HistoryOutlined/> Stylo</div>
                        <div onClick={() => this.searchFreeText("Em1")} className={`search-result`}><HistoryOutlined/> Em1</div>
                        <div onClick={() => this.searchFreeText("Icon E")} className={`search-result`}><HistoryOutlined/> Icon E</div>
                        <div onClick={() => this.searchFreeText("CUV E")} className={`search-result`}><HistoryOutlined/> CUV E</div>
                        <div onClick={() => this.searchFreeText("Honda 20 jutaan")} className={`search-result`}><HistoryOutlined/> Honda 20 jutaan</div>
                        <div onClick={() => this.searchFreeText("PCX")} className={`search-result`}><HistoryOutlined/> PCX</div>
                        <div onClick={() => this.searchFreeText("Scoopy")} className={`search-result`}><HistoryOutlined/> Scoopy</div>
                        <div onClick={() => this.searchFreeText("CRF")} className={`search-result`}><HistoryOutlined/> CRF</div>
                        <div onClick={() => this.searchFreeText("Vario")} className={`search-result`}><HistoryOutlined/> Vario</div>
                        <div onClick={() => this.searchFreeText("ADV 160")} className={`search-result`}><HistoryOutlined/> ADV 160</div>
                    </div>

                    <div style={this.props.isDesktop ? {display: 'none'} : {}}><br/></div>

                    <span style={{color: "#b0b0b0", marginTop: 20}}>TERAKHIR DILIHAT:</span>
                    <Row>
                        {lastSeenVariant.map((item: any, i: number) =>
                            <Col style={{padding: 5, lineHeight: 2}} key={i} xl={{span: 3}} lg={{span: 3}} md={{span: 6}} sm={{span: 8}} xs={{span: 8}}>
                                <a href={item?.link} style={{lineHeight: 0}}>
                                    <img alt={`lastseenimage`} style={{width: "100%"}} src={item?.url_image}/>
                                    <span style={{padding: 0, margin: 0, lineHeight: 0, fontSize: "70%", color: "gray"}}>
                                        {item?.name?.split('', 15).reduce((o: any, c: any) => o.length === 14 ? `${o}${c}...` : `${o}${c}`, '')}
                                    </span>
                                </a>
                            </Col>
                        )}
                    </Row>
                </Drawer>
            </React.Fragment>
        );
    }
}

export default withRouter(SearchComponent);
